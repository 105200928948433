.deep-dive-title {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.16px;
    color: #171717;
    padding-top: 20px;
}

.donut-chart {
    width: 100%;
    height: 240px;
    padding-top: 6px;
}

.legend-icon {
    height: 12px;
    width: 12px;
    border-radius: 6px;
    display: inline-block;
}

.legend-standard {
    background-color: #0028CA;
}

.legend-fitted {
    background-color: #1050FF;
}

.legend-not-fitted {
    background-color: #FDD13A;
}

.legend-not-appliable {
    background-color: #828282;
}

.legend-text {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #171717;
    padding-left: 18px;
    display: inline-block;
}
.legend-container {
    width: 142px;
    padding-top: 24px;
    /* margin-left:40%; */
}
.legend-div {
    padding-bottom: 20px;
}