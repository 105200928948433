/* UI Update */

/* Body */
body,
.btn {
    font-family: 'Roboto', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8b8b8b;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #636363;
}

.pr-10px {
    padding-right: 10px;
}

.sbd-bg-light {
    background-color: #f2f2f2 !important;
}

.container-main-margin {
    margin-left: 100px
}

/* Navbar-start */
.nav-border-bottom {
    border-bottom: 1px solid #DCDCDC;
}

.top-navbar-height {
    height: 61px
}

.nav-logo {
    margin-bottom: 40px;
    width: 100%;
    height: 60px;
    padding-top: 10px;
    border-bottom: 1px solid #DCDCDC;
}

.img-logo-icon {
    display: inline-block;
    width: 32px !important;
    margin-left: 30px;
    margin-top: 4px;
}

.img-logo {
    width: 175px;
    display: inline-block;
    margin-left: 10px;
    margin-top: 12px;
    position: absolute;
}

/* SideNav.css */

.side-nav {
    width: 100px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #0028CA;
    overflow-x: hidden;
    transition: 0.5s;
    /* padding-top: 60px; */
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    color: #fff;
    border-radius: 9px 0 0 0;
}

.side-nav.open {
    width: 280px;
    transform: translateX(0);
}

.close-btn {
    font-size: 20px;
    color: white;
    text-decoration: none;
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    margin-top: 20px;
    cursor: pointer;
}

.close-btn:hover {
    color: #818181;
}

.side-nav-ul {
    list-style-type: none;
}

.side-nav-active {
    border-left: 4px solid #FFFFFF;
    padding-left: 36px !important;
    cursor: pointer !important;
}

li.side-nav-li {
    height: 65px;
    margin-left: -32px;
    padding-left: 40px;
    padding-top: 22px;
    /* cursor: default; */
}

span.side-nav-text {
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF !important;
    margin-left: 15px;
    margin-top: -2px;
    letter-spacing: 0.16px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

.img-logo {
    visibility: hidden;
    opacity: 0;
}

.side-nav.open .img-logo {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 1s linear;
}

.side-nav.open span.side-nav-text {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 1s linear;
}

li.side-nav-li:hover {
    border-left: 4px solid #FFFFFF;
    padding-left: 36px !important;
}

.top-nav-float {
    position: absolute;
    right: 0;
    top: 0;
}

.top-nav-div {
    display: inline-block;
    cursor: pointer;
    height: 56px;
    min-width: 250px;
}

.top-nav-text {
    padding: 17px 45px 17px 45px;
    border-left: 1px solid #171717;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #171717;
    height: 60px;
}

.top-nav-arrow {
    padding-left: 20px;
    padding-right: 10px;
}

.dropdown-logout {
    border-radius: 0px !important;
    background-color: #FFFFFF !important;
    margin-top: 0px !important;
    width: 100% !important;
    min-width: 3rem !important;
    transform: none !important;
    top: 60px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.top-nav-list {
    height: 50px;
    border-bottom: 1px solid #DCDCDC;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.6;
    color: #333;
    white-space: nowrap;
}

.top-nav-logout {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #171717;
    padding: 10px 25px;
}

.logout-link {
    text-decoration: none;
    color: #000;
}

/* Navbar-end */

/* Dropdown */
/* Use a blue tick as the background image for the checked state */
.form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%231050ff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}

/* Set the background color and border color when checked */
.form-check-input:checked {
    background-color: #ffffff;
    /* White background */
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.dropdown-toggle {
    white-space: nowrap;
    text-align: left;
    height: 44px;
    border: 1px solid #171717;
    border-radius: 4px;
    color: #000 !important;
}

.dropdown-toggle::after {
    /* float: right; */
    content: url('../img/dropdown_black_down.png');
    border: none;
    position: absolute;
    top: 11px;
    right: 11px;
    background: white;
    padding: 0px 5px;
    border-radius: 3px;
}

.disabled-dropdown.dropdown-toggle::after {
    background: #f7f7f7;
}

.dropdown-menu.show {
    display: block;
    min-width: 100% !important;
    width: 15rem;
}

.font-bold500 {
    font-weight: 500;
}

/* Build btn */
.btn-primary {
    font-size: 14px;
    min-width: 115px;
    height: 44px !important;
    border-radius: 4px;
    background-color: rgb(16, 80, 255);
}

/* dropdown inner menu */
.dropdown-item div {
    font-weight: 500;
    margin-left: 2px;
}

label.form-check-label.cursor {
    white-space: normal;
}

.col-md-3 div .dropdown-item {
    padding-left: 10px;
    padding-right: 10px;
}

.features-menu .col-md-3 {
    padding-right: 0;
}

.cst-top-position {
    top: 2.75rem !important;
    margin-top: 0 !important;
}

.features-menu {
    margin-left: 0px !important;
}

.dropdown-menu .dropdown-submenu {
    left: 99%;
}

.dropdown-submenu {
    width: 100%;
    top: -6px;
}

.disabled-dropdown {
    color: #dcdcdc !important;
    background-color: #f2f2f2 !important;
    border-color: var(--bs-border-color) !important;
}

.disabled-label {
    color: #dcdcdc !important;
}

/* dropdown inner menu - End */

.serach-box {
    white-space: nowrap;
    text-align: left;
    height: 44px;
    border: 1px solid #171717;
    border-radius: 4px;
    color: #000 !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 8px 10px 8px 16px;
}

.search-dropdown-menu {
    margin-left: 15px;
    max-height: 440px;
    width: 1080px;
    background: transparent;
    border: none;
    box-shadow: none !important;
    display: block;
}

.search-dropdown-menu .main {
    overflow-y: auto;
    max-height: 400px;
    width: 270px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    height: unset;
}

.search-dropdown-menu .dropdown-submenu {
    position: relative;
    display: block;
    left: 0;
}

.search-dropdown-menu .item_box_border {
    border-bottom-style: solid;
    border-bottom-color: #F4F4F4;
    border-bottom-width: 2px;
}

.search-dropdown-menu .item-box {
    padding-top: 5px;
    padding-bottom: 5px;
}

.search-dropdown-menu .parent-label {
    color: #9b9b9b;
    font-weight: normal;
    font-size: 14px;
}

.search-dropdown-menu label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.search-dropdown-menu .container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: black;
    font-weight: 500;
    margin-left: 0;
}

.search-dropdown-menu .feature-name-crop {
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    width: 180px;
    margin-left: -5px;
}

.search-dropdown-menu .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
}

.search-dropdown-menu .checkmark {
    position: absolute;
    top: 3px !important;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: solid #e9e9e9;
    border-width: 2px;
    border-radius: 3px;
}

.search-dropdown-menu .span-text {
    padding-left: 5px;
}

.search-dropdown-menu .container-checkbox input:checked~.checkmark:after {
    display: block;
}

.search-dropdown-menu .container-checkbox .checkmark:after {
    left: 4px;
    top: 0px;
    width: 6px;
    height: 10px;
    border: solid #1050ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.search-dropdown-menu .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.search-dropdown-menu .sub-child {
    list-style: none;
    padding-left: 1px;
    cursor: pointer;
}

.search-dropdown-menu .sub-child .main {
    overflow-y: auto;
    max-height: 400px;
    width: 253px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    height: fit-content;
}

/* Dropdown - End */

/* Card */
.card.shadow {
    border-radius: 4px;
}

/* deep dive - START */
li.list-group-item.ps-3 {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #000;
    /* font-weight: 500; */
    letter-spacing: 0.16px;
    font-size: 16px;
}

.sbd-border-start {
    border-left: 1px solid #000 !important;
}

.sbd-border-end {
    border-right: 1px solid #000 !important;
}

.sbd-border-top {
    border-top: 1px solid #000 !important;
}

.sbd-border-bottom {
    border-bottom: 1px solid #000 !important;
}

.sbd-card-body {
    padding: 1.5rem;
}

.pencilImg {
    width: 20px;
    margin: 0.5rem;
}

.h6-insight-name {
    margin-left: 1.3rem;
    font-size: 14px;
}

.tour-text:hover {
    color: #1050FF;
}

.tour-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-decoration: underline;
    color: #1050FF;
    cursor: pointer;
}

.sbd-sm {
    font-size: 12px !important;
    margin-bottom: 5px;
}

/* .vertical-line{
    border-left: 1px solid rgb(220, 220, 220);
    height: 100%;
    width: 1px;
    top: 0;
    position: absolute;
    left: 58%;
} */

.vertical-line {
    position: relative;
}

.vertical-line::before {
    position: absolute;
    content: '';
    top: -24px;
    bottom: -24px;
    left: 0;
    background-color: rgb(220, 220, 220);
    width: 1px;
}

.adv-filter-btn-span {
    font-size: 14px;
    cursor: pointer;
    color: #1050ff;
    margin-right: 5px;
}

.adv-blue-img {
    margin-right: 5px;
}

.btn-row {
    border-bottom: 1px solid #dee2e6;
    color: #1050FF;
}

.export-btn-col {
    padding: 20px 0;
    cursor: pointer;
}

.report-btn-col {
    border-left: 1px solid #dee2e6;
    padding: 20px 0;
    cursor: pointer;
}

.deep-dive-img {
    height: 21px;
    width: 42px;
    display: inline-block;
}

.deep-dive-nav {
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #1050FF;
    display: contents;
    padding-left: 16px;
    vertical-align: top;
}

.deep-dive-nav-small {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 500;
    color: #1050FF;
    display: inline-block;
    padding-left: 9px;
    vertical-align: top;
    margin-bottom: 0;
}

.div-padding {
    padding-left: 30px !important;
}

.sub-heading-row {
    padding-top: 25px;
}

.heading-text {
    font-size: 17px;
    letter-spacing: 0.16px;
    color: #828282;
}

.main {
    height: 65px;
}

.standard-td {
    vertical-align: middle;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #171717;
    padding-left: 18px;
    cursor: pointer;
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.table-content {
    padding-top: 40px;
    padding-left: 25px;
    padding-bottom: 10px;
}

.div-right-padding {
    padding-right: 45px;
}

.cancel-btn-style {
    width: 100%;
    color: #1050FF !important;
    letter-spacing: 0.16px;
    height: 48px;
    background-color: white !important;
    border: 2px solid #1050FF;
}

.message-main-div {
    padding-top: 40px;
    padding-bottom: 30px;
}

.message-heading-padding {
    padding-top: 25px;
    padding-bottom: 25px;
}

.faq-heading {
    vertical-align: middle;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #171717;
}

.div-right-padding {
    padding-right: 45px;
}

.textarea-box {
    border: 1px solid rgba(69, 90, 100, 0.16);
    width: 100%;
    height: 230px;
    padding-left: 12px;
    padding-top: 12px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #171717;
}

.submit-btn-style {
    width: 100%;
    color: white !important;
    letter-spacing: 0.16px;
    height: 48px;
    background-color: #1050FF !important;
}

.submitBtnDiv {
    padding: 0 0 10px 0;
}

/* deep dive - END */

/* Result Table */

.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: none;
    --bs-table-bg-state: none;
}

.result-value-td:hover {
    background: #e7edff !important;
}

.button-icons-active {
    min-width: 50px;
    height: 52px !important;
    border-radius: 4px;
    background: #ffffff !important;
    border: none !important;
    margin: 0px 1px;
    /* margin-right: 12px; */
}

.button-text-inactive {
    text-align: center;
    font-size: 14px;
    color: #dcdcdc;
    display: block;
}

.button-text-active {
    text-align: center;
    font-size: 14px;
    color: #000000;
    display: block;
    /* position: absolute; */
}

.save-insight-btn {
    width: 35px !important;
    padding: 0;
    height: 39px !important;
    min-width: 35px;
}

.inner-header-img {
    display: none;
    width: 20px;
    cursor: pointer;
    padding-right: 5px;
    margin-top: 3px;
    position: relative;
}

.pull-right {
    float: right;
}

td:hover img.inner-header-img {
    display: block;
}

.outer-header {
    color: #171717;
    font-weight: 400;
    font-size: 14px;
    min-width: 170px;
    display: inline-block;
    padding-right: 0px;
    padding-top: 10px;
    padding-left: 15px;
    height: 41px;
    margin-bottom: -5px;
    overflow-y: hidden;
}

.fixed-width {
    width: 170px;
}

.inner-header-icon {
    display: none;
    width: 25px;
    cursor: pointer;
    padding-right: 2px;
    margin-top: -4px;
    right: 0;
    position: relative;
}

.row_column_hover {
    text-wrap: balance;
    vertical-align: middle;
}

.row_column_hover:hover {
    background-color: #f1f1f1 !important;
    cursor: pointer;
    text-wrap: balance;
}

.table-div {
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 5px;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 1px;
}

.div-relative {
    position: relative;
}

.table-container {
    display: block;
    width: 100%;
}

.div-border-top {
    border-top: 1px solid #000;
    /* max-width: 1195px; */
    overflow-x: hidden;
    overflow-y: hidden;
}

.div-table-split {
    display: flex;
    width: 100%;
}

.div-border-right {
    border-right: 1px solid #000;
    overflow-y: hidden;
    overflow-x: hidden;
}

.div-table-split-left {
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
    /* flex: 0 0 170px; */
    height: 100%;
    max-height: 54vh;
    /* overflow-x: scroll; */
}

.trim-col-td {
    min-width: unset !important;
    max-width: fit-content !important;
}

.trim-col-td span {
    width: auto !important;
}

.header-top-right {
    border-bottom: 1px solid #000;
    overflow-x: hidden;
}

.height-86 {
    height: 86px !important;
}

.right-height {
    overflow-x: hidden;
    overflow-y: hidden;
}

.div-table-split-right {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    vertical-align: top;
    margin-left: 0px;
    /* flex: 0 0 990px; */
    height: 100%;
}

.max-width-1000 {
    max-width: 1000px;
}

.outer-header-span {
    margin-top: 43px;
}

.header-top-col {
    color: gray;
    font-weight: 400;
    font-size: 14px;
}

.outer-header-fix:first-child {
    min-width: 170px;
}

.border_none:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 0px;
    width: 0%;
}

.inner-header-top {
    width: 130px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inner-header-img {
    display: none;
    width: 20px;
    cursor: pointer;
    padding-right: 5px;
    margin-top: 3px;
    position: relative;
}

.pr-1px {
    padding-right: 1px;
}

.bottom-div-right {
    overflow-x: auto;
    overflow-y: hidden;
}

.result_bottom_div {
    position: relative;
    max-width: 992px;
}

.width-1000 {
    width: 1000px;
}

.scroll-fixed {
    position: absolute;
    left: 0px;
    top: 0px;
}

.sort-by-low-span {
    display: block;
    margin-top: -12px;
    cursor: pointer;
    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.sort-by-up-span {
    display: block;
    margin-top: 0;
    cursor: pointer;
    margin-bottom: -6px;
    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.form-control-sortby {
    height: 40px;
    width: 115px;
    margin-left: 8px;
    margin-top: -6px;
}

.sortby-img {
    padding-right: 10px;
    width: 17.5px;
    height: 14px;
}

.sortby-hr-one {
    margin-bottom: 10px;
    margin-left: -10px !important;
}

.sortby-hr-two {
    margin-top: 15px;
    margin-left: -15px !important;
}

.show-above-label {
    padding-top: 3px !important;
}

.sortby-remove-span {
    display: block;
    margin-top: -5px;
    padding-left: 29px;
    cursor: pointer;
    /* padding-bottom: 15px; */
}

.fixed-width {
    width: 170px !important;
}

.fixed-width-values {
    cursor: pointer;
    background: rgb(255, 255, 255);
}

.fake-scroll-bottom {
    position: absolute;
    right: 0;
    bottom: -20px;
    background: #fff;
    color: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    height: 1px;
}

.bottom-div-table-hidden {
    margin-bottom: 0px;
    border-left: 0px;
    visibility: hidden;
}

.fixed-height {
    height: 0px;
}

.table-bordered td {
    border: none;
    border-bottom: solid 1px #E9E9E9;
    min-width: 170px;
    max-width: 170px;
    height: 40px;
    overflow: hidden;
}

.col-filter {
    height: 0px !important;
    padding: 0px !important;
    border: 0px !important;
    top: -9px;
    position: relative;
    overflow: unset !important;
}

.show-filter {
    position: absolute;
    max-width: 147px;
    margin-top: 8px;
}

.filter-dropdown {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    /* position: absolute; */
    font-size: 12px;
    letter-spacing: 0.3px;
    width: -webkit-fill-available;
    padding: 19px 18px;
    margin-left: 0px;
}

.filter-checkbox {
    width: 1.3em;
    height: 1.3em;
    margin-right: 8px;
}

.filter-checkbox-label {
    padding-top: 3px;
}

.sortby-hr-one {
    border-top: 1px solid #696969;
    box-sizing: content-box;
    margin: 15px 0px 7px -11px;
}

.m-0 {
    margin: 0 !important;
}

hr {
    margin-top: 22px;
    margin-bottom: 22px;
    border: 0;
    border-top: 1px solid #eee;
}

.form-control {
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccd0d2;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control,
output {
    display: block;
    font-size: 14px;
    line-height: 1.6;
    color: #555;
}

.container-label {
    display: block;
    position: relative;
    padding-left: 10px;
    padding-top: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: black;
    font-weight: 500;
}

.fixed-padding {
    padding-left: 7px;
    width: 160px !important;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text_center {
    padding-left: 10px;
}

.col-padding {
    padding-left: 7px;
    width: 160px !important;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-box-row:nth-child(1) {
    position: relative;
    left: 0px;
    top: 0px;
    /* z-index: 1; */
    background-color: white;
    overflow-x: hidden;
}

.header-box-row {
    border-bottom: 1px solid #000;
    width: 100%;
    display: table;
}

.header-box-div {
    display: inline-block;
    color: #171717;
    font-weight: 400;
    font-size: 14px;
    padding-left: 15px;
    height: 35px;
    padding-top: 10px;
    padding-right: 0px;
}

.header-box-content {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-right-style {
    position: relative;
    border-right-color: #9b9b9b;
}

.border-right-style:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 25px;
    width: 50%;
}

.outer-header-top {
    color: #171717;
    height: 43px;
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    padding-right: 0px;
    border-bottom: 1px solid black !important;
    width: 170px;
    display: inline-block;
    padding-top: 12px;
}

.bottom-div-table {
    border: 0px transparent;
    margin-bottom: 1px;
}

.bottom-div-left-table {
    margin-bottom: 0px;
}

.row_column_hover:hover img.inner-header-icon {
    display: block;
}

.inner-header {
    max-width: 155px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* CSS for horizontal scroll */
.frozen-horizontal {
    position: sticky;
    left: 0;
    background-color: #f1f1f1;
    /* Add background color as needed */
    z-index: 1;
    /* Ensure it's on top of other elements */
}

/* CSS for vertical scroll */
.frozen-vertical {
    position: sticky;
    top: 0;
    background-color: #f1f1f1;
    /* Add background color as needed */
    z-index: 1;
    /* Ensure it's on top of other elements */
}

.temp-box {
    max-width: 1000px;
    overflow-x: scroll;
    max-height: 205px;
}

.fixed-width-values:hover {
    background: #e7edff !important;
}

.bottom-div-right {
    overflow-x: auto;
    overflow-y: auto;
}

.div-table-split-right {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    vertical-align: top;
    margin-left: 0px;
    /* flex: 0 0 990px; */
    height: 100%;
    max-height: 54vh;
}

.bottom-div {
    /* max-height: 675px;
    min-height: 675px; */
    max-height: 54vh;
    min-height: 54vh;
    overflow-y: auto;
    overflow-x: auto;
    /* margin-left: -1px; */
}

/* Result Table - End */

/* Advanced Filters Modal */

.adv-modal-dialog {
    height: auto;
    width: 665px;
}

.adv-modal-content {
    border-radius: 0px;
}

.adv-first-row {
    height: 58px;
    border-bottom: 1px solid rgb(220, 220, 220);
    width: 664px;
    margin-left: -16px;
}

.adv-config-tab {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #000000;
    display: inline-block;
    padding-top: 10px;
    cursor: pointer;
}

.adv-filter-tab-title {
    margin-left: 25px;
    border-bottom: 1px solid rgb(0, 0, 0);
}

.adv-config-tab-title {
    margin-left: 40px;
}

.adv-config-close {
    border-left: 1px solid rgb(220, 220, 220);
    height: 72px;
    margin-top: -15px;
    position: absolute;
    float: right;
    display: inline-block;
    width: 100px;
    right: 0px;
}

.adv-close-img {
    margin-top: 33px;
    cursor: pointer;
}

.adv-third-row {
    width: 664px;
    height: 82px;
    border-top: 1px solid rgb(220, 220, 220);
    margin-top: 30px;
    padding-top: 25px;
    margin-left: -16px;
}

.pull-left {
    float: left;
}

.adv-reset-btn {
    color: rgb(16, 80, 255);
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    width: 160px;
    height: 48px;
}

.adv-cancel-btn {
    color: rgb(23, 23, 23);
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    width: 160px;
    height: 48px;
}

.adv-apply-btn {
    color: rgb(245, 246, 248);
    font-size: 14px;
    cursor: pointer;
    width: 160px;
    height: 48px;
    background: rgb(16, 80, 255);
}

.adv-second-row {
    height: auto;
    margin-bottom: -30px;
}

.adv-left-col {
    padding-left: 0px;
    padding-right: 0px;
}

.adv-right-col {
    border-left: 1px solid rgb(220, 220, 220);
    height: auto;
    padding-left: 35px;
}

/* inputs  */
.input-component-wraper {
    height: 125px;
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-left: 40px;
}

.input-wraper {
    width: 255px;
    padding-top: 20px;
}

.adv-label {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: rgb(0, 0, 0);
    padding-top: 32px;
    font-weight: normal;
}

.small-text-input-left {
    width: 60px;
    display: inline-block;
    height: 25px;
    position: absolute;
    top: 107px;
    left: 176px;
    border: 1px solid rgb(220, 220, 220);
    font-size: 12px;
    color: rgb(0, 0, 0);
    padding-left: 5px;
}

.small-text-input-right {
    left: 239px;
}

.noUi-horizontal {
    height: 4px;
}

.noUi-target,
.noUi-target * {
    outline: none !important;
    box-shadow: none !important;
}

.noUi-connect {
    background: #1050ff;
}

.noUi-connects {
    border-radius: 3px;
    background-color: #828282;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    right: -10px;
    left: auto;
    cursor: pointer;
    border-radius: 10px;
    height: 10px;
    width: 10px;
    top: -4px;
}

.noUi-handle:before,
.noUi-handle:after {
    background: none;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 0px;
}

.my-slider-ui .noUi-value {
    color: #000000;
    font-size: 14px !important;
    top: -3px;
    margin-left: -5px;
}

.my-slider-ui div[class~="noUi-handle-lower"]>.noUi-tooltip {
    margin-left: 35px;
}

.noUi-pips {
    position: absolute;
    color: gray;
}

.noUi-marker-horizontal {
    height: 0px !important;
}

.my-slider-ui .noUi-value {
    color: #000000;
    font-size: 14px !important;
    top: -3px;
    margin-left: -5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 0px;
}

.my-slider-ui .noUi-value {
    color: #000000;
    font-size: 14px !important;
    top: -3px;
    margin-left: -5px;
}

.noUi-handle {
    background: #1050ff;
    border-radius: 3px;
    border: none !important;
    border-radius: 3px;
    cursor: default;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.container-checkbox {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: black;
    margin-left: 25px;
}

.container-checkbox-custom {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: rgb(0, 0, 0);
    padding-top: 32px;
    font-weight: normal;
}

.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
}

.checkmark {
    position: absolute;
    top: 36px !important;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: solid #e9e9e9;
    border-width: 2px;
    border-radius: 3px;
}

.container-checkbox-horizontal {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #000000;
    font-size: 12px;
    font-weight: 400 !important;
    margin-right: 15px;
}

.container-checkbox-horizontal input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
}

.container-checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #1050ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.container-checkbox input:checked~.checkmark:after {
    display: block;
}

.checkmark-radio {
    position: absolute;
    top: 1px !important;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #ffffff;
    border: solid #000000;
    border-width: 1px;
    border-radius: 50%;
}

.checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox-horizontal input:checked~.checkmark-radio:after {
    display: block;
}

.container-checkbox-horizontal .checkmark-radio:after {
    left: 3px;
    top: 2.5px;
    width: 7px;
    height: 7px;
    border: solid #1050ff;
    border-width: 3px 3px 3px 4px;
    border-radius: 50%;
    transform: rotate(45deg);
}

.radio-label-tootlip {
    font-size: 12px;
    color: rgb(130, 130, 130);
    padding-left: 8px;
}

.font-12 {
    font-size: 12px;
}

.adv-form-label-cust {
    margin-top: 18px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    color: rgb(0, 0, 0);
    word-spacing: 0.4px;
    font-weight: 400;
}

.pb-8px {
    padding-bottom: 8px;
}

.custom_filter_deselect {
    border: 1px solid black;
}

.custom_filter {
    margin-bottom: 15px;
    height: 44px;
    text-align: left;
    width: 252px;
    border: 1px solid black;
    background-color: #fff;
    border-radius: 3px;
}

.m-2px {
    margin: 2px;
}

.adv-dropdown-custom {
    background: transparent;
    padding-top: 3px;
    border: none;
    padding-left: 0px;
}

.adv-dropdown-custom::after {
    position: absolute;
    right: 10px;
    top: 7px;
}

.adv-col-12-cust {
    padding: 2px 5px 5px;
    height: 24px;
    margin-bottom: 6px;
}

.inner-label-all {
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.inner-label-filtered {
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1050FF;
}

.adv-custom-inner-label-all {
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(233, 233, 233);
}

.adv-container {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: black;
    font-weight: 500;
}

.adv-container:after,
.adv-container:before {
    display: table;
    content: " ";
}

.adv-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0px;
    height: 0px;
}

.adv-container input:checked~.adv-checkmark {
    background-color: #ffffff;
    border: solid #fbfbfb;
    border-width: 2px;
    border-radius: 3px;
}


.adv-checkmark {
    position: absolute;
    top: 3px !important;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: solid #e9e9e9;
    border-width: 2px;
    border-radius: 3px;
}

.span-text {
    padding-left: 5px;
}

.adv-container input:checked~.adv-checkmark:after {
    display: block;
}

.adv-container .adv-checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid #1050ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.adv-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.item-box {
    padding: 5px 15px;
}

.show {
    display: block;
}

/* Advanced Filters Modal - End */

/* Save insight popup */
.save-popup-heading {
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.save-insight-img {
    width: 30px !important;
    padding: 0;
    height: 45px !important;
    min-width: 32px;
}

.modal-footer {
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.save-popup-textbox {
    width: 100%;
    font-size: 14px;
    padding: 8px 12px;
    margin-bottom: 15px;
}

.hint-span {
    font-size: 14px;
    color: rgb(74, 74, 74);
}

.row.footer-border {
    margin-top: 22px;
}

.footer-border::before {
    content: '';
    border-top: 1px solid #ccc;
    width: 100%;
    margin-top: 14px;
    position: absolute;
    left: 0;
    bottom: 60px;
}

/* Save insight popup - End */

/* 60 second tour popup */

.tour-guide-modal {
    width: 480px;
}

.tour-guide-modal-content {
    border: none !important;
}

.tour-guide-modal-body {
    padding: 0px;
}


.tour-guide-media {
    height: 360px;
    background-color: #DCDCDC;
    border-radius: 4px;
    padding-top: 35px;
}

.tour-guide-text {
    height: 280px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 48px;
}

.tour-guide-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.16px;
    color: #000000;
    padding-top: 56px;
}

.tour-guide-paragraph {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.16px;
    color: #828282;
    padding-top: 15px;
}

.tour-guide-navigation-left {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    float: left;
    letter-spacing: 0.16px;
    color: #828282;
    padding-top: 48px;
    display: inline-block;
    cursor: pointer;
}

.tour-guide-navigation-right {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    float: right;
    letter-spacing: 0.16px;
    color: #1050FF;
    padding-top: 48px;
    display: inline-block;
    cursor: pointer;
}

.dot-close {
    height: 30px;
    width: 30px;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 3px;
    cursor: pointer;
}

.dot-img {
    position: relative;
    top: 3px;
    left: 3px;
}


.tour-guide-media-pt70 {
    height: 360px;
    background-color: #DCDCDC;
    border-radius: 4px;
    padding-top: 70px;
}

.tour-guide-navigation-left-h72 {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    float: left;
    letter-spacing: 0.16px;
    color: #828282;
    padding-top: 72px;
    display: inline-block;
    cursor: pointer;
}

.tour-guide-navigation-right-h72 {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    float: right;
    letter-spacing: 0.16px;
    color: #1050FF;
    padding-top: 72px;
    display: inline-block;
    cursor: pointer;
}

.tour-guide-color-black {
    color: #14264f;
}

.tour-guide-color-blue {
    color: #4b84ff;
}

.tour-guide-color-yellow {
    color: #ffca4d;
}

.tour-guide-navigation-center-pt74 {
    display: inline-block;
    text-align: center;
    padding-top: 74px;
    margin-left: 135px;
}

.dot-black {
    height: 8px;
    width: 8px;
    background-color: #171717;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
}

.dot-grey {
    height: 8px;
    width: 8px;
    background-color: #DCDCDC;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
}

.tour-guide-navigation-center {
    display: inline-block;
    text-align: center;
    padding-top: 50px;
    margin-left: 135px;
}

.gif-img {
    width: 480px;
    padding-top: 3px;
}

.tour-img-main {
    width: 420px;
}

/* 60 second tour popup - End */

/* newInsightPopup */
.newInsightPopup-title {
    font-weight: 500;
    font-size: 16px;
    color: black;
}

.newInsightPopup-font-size-14 {
    font-size: 14px;
    color: #636b6f;
}

.newInsightPopup-pt-13 {
    padding-top: 13px;
}

.btn-link {
    font-weight: 500;
    color: #3097d1;
    border-radius: 0;
    text-decoration: none;
}

.btn-link:hover {
    color: #216a94;
    text-decoration: underline;
    background-color: transparent;
}

.modal-dialog-mt-15 {
    margin-top: 15%;
}

.recent-div {
    width: 350px;
    height: auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.175) !important;
    border: none !important;
}

.recent-tabs {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-align: center;
    height: 45px;
    cursor: pointer;
    border-bottom: solid 1px #e9e9e9;
    padding-top: 8px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.recent-inactive-tab {
    color: rgb(189, 189, 189);
}

.recent-border {
    border-left: solid 1px #e9e9e9;
}

.recent-container {
    height: 70px;
    border-bottom: solid 1px #e9e9e9;
    padding-top: 15px;
    padding-left: 20px;
}

.recent-title {
    font-size: 12px;
    color: #000000;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 310px;
}

.recent-title:hover {
    color: #1050ff;
}

.recent-date {
    font-size: 12px;
    color: #bdbdbd;
    padding-top: 4px;
}

.recent-show-insights {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-align: center;
    height: 45px;
    cursor: pointer;
    color: #1050ff;
    padding-top: 10px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.recent-img {
    margin-top: -4px;
    margin-right: 10px;
}

.hide-tab-div {
    display: none;
}

.adv-custom-dropdown-menu {
    width: 100%;
    font-size: 14px;
    color: rgb(74, 74, 74);
    max-height: 250px;
    top: 40px;
}

.adv-custom-li-wraper {
    width: 100%;
    font-size: 14px;
    color: rgb(74, 74, 74);
    max-height: 199px;
    overflow: auto;
}

/* btn */
.active-btn-custom {
    /* min-width: 70px;
    height: 52px !important;
    margin: 0px 1px; */
    background-color: rgb(16, 80, 255) !important;
}

.active-btn-custom>span {
    color: #fff;
}

.open>.dropdown-menu {
    display: block;
}


/* Inbsight container */
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-arrow-left:before {
    content: "\E091";
}

.backToMain-btn {
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    color: rgb(16, 80, 255);
    font-weight: 500;
    margin-left: -10px;
    letter-spacing: 1.35px;
    border: none;
}

.backToMain-btn:hover {
    color: rgb(16, 80, 255);
    text-decoration: none;
    background-color: transparent;
}

.fav-tabs {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    text-align: center;
    color: #000000;
    height: 40px;
    cursor: pointer;
    border-radius: 2px;
}

.fav-active-tab {
    border-bottom: 2px solid rgb(23, 23, 23)
}

.fav-in-active-tab {
    border-bottom: 2px solid rgb(245, 246, 248);
}

.card-row {
    border-bottom: 2px solid rgb(245, 246, 248);
    padding-right: 0;
    padding-left: 0;
}

.card-border {
    background-color: white;
    border: solid;
    border-color: white;
    border-width: thin;
    border-radius: 5px;
}

.card-content-common {
    padding-left: 21px;
    padding-top: 5px;
}

.insight-title {
    color: rgb(23, 23, 23);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 0;
}

.max-whidth-100 {
    max-width: 100px;
}

.date-p {
    color: rgb(189, 189, 189);
    font-size: 12px;
}

.insight-action-div {
    /* cursor: pointer; */
    max-width: 100px;
    display: none;
}

.insight-action-text {
    font-size: 12px;
    color: rgb(16, 80, 255);
    font-weight: 500;
    cursor: pointer;
}

.card-border:hover .insight-action-div {
    display: block;
}

.container-cm-custom {
    max-width: 785px;
}

/* Inbsight container - End */


/* Download modal - start */

.cell-modal-body {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: -10px;
}

.cell-modal-info-img-div {
    height: 40px;
    width: 40px;
    display: inline-block;
}

.cell-modal-info-img {
    margin-top: 9px;
}

.cell-modal-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 72px;
    letter-spacing: 0.16px;
    color: #000000;
    display: inline-block;
    vertical-align: middle;
    margin-top: 12px;
    padding-left: 15px;
}

.cell-modal-info-div {
    padding-top: 10px;
}

.cell-modal-info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #000000;
}

.cell-modal-footer-div {
    padding-top: 40px;
    padding-bottom: 25px;
}

.cell-modal-buttons {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.cell-modal-export {
    background: #1050FF;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.16px;
    height: 50px;
    width: 170px;
    border: none;
    display: inline-block;
    font-weight: 500;
    margin-left: 6px;
}

/* Download modal - End */

/* Download modal - START */
.div-modal-heading {
    font-weight: 600;
    font-size: 16px;
    color: black;
    margin-top: 12px;
}

.div-modal-heading-gray {
    font-weight: 500;
    font-size: 14px;
    color: #4a4a4a;
}

/* Download modal - End */



@media (min-width: 2500px) {
    .col-md-8-custom {
        width: 50%;
    }
}

@media screen and (max-width: 1250px) {

    .txt-14px,
    .dropdown-menu,
    .dropdown-item {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 1200px) {

    .txt-14px,
    .dropdown-menu,
    .dropdown-item {
        font-size: 12px !important;
    }
}

@media (min-width: 768px) {
    .modal {
        --bs-modal-width: 555px;
    }
}

.nav-link {
    cursor: pointer !important;
}