.shadow{
    box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125) !important;
}

.sbd-bg-light{
    background-color: rgb(247, 250, 255) !important;
}

.sbd-dropdown{
    position: absolute !important;
}

.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}  

.flex-child:first-child {
  
} 
body, .btn{
    font-family: 'Nunito', sans-serif !important;
}

.dropdown-menu li {
    position: relative;
    /* cursor: pointer; */
    font-weight: 500;
    }
    .dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
    }
    .dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
    }
.dropdown-menu{
    min-width: 215px !important;
    border-radius: 4px !important;
    box-shadow: 0 6px 12px rgba(0,0,0,.175) !important;
}
.float-right{
    float: right!important;
}
.img-mt-7px{
    margin-top: 7px;
}
.lable{
    cursor: default;
    color: #282828 !important;
    font-weight: 500 !important;
    text-decoration: none;
    /* font-size: 16px !important; */
}
.txt-14px{
    font-size: 18px !important;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #fff !important;
}
.features-menu{
    margin-top:5px !important;
    border-radius: 2px !important;;
    margin-left: -113% !important;;
    max-height: 440px !important;;
    width: 1080px !important;
    background-color: white !important;;
}
.features-list{
    font-weight: 500;
    color: #000000 !important;
    min-height: 398px !important;
    max-height: 398px !important;
    margin-bottom: -5px;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    font-size: 14px !important;
    
}
.features-list::-webkit-scrollbar, .vehicles-list::-webkit-scrollbar, .sbd-result-table::-webkit-scrollbar {
    width: 5px;
}
.features-list::-webkit-scrollbar-thumb, .vehicles-list::-webkit-scrollbar-thumb, .sbd-result-table::-webkit-scrollbar {
    background: #E9E9E9;
    border-radius: 20px;
}
.features-list::-webkit-scrollbar-track, .vehicles-list::-webkit-scrollbar-track, .sbd-result-table::-webkit-scrollbar {
    background: #ffffff;
    border-radius: 20px;
}
/* .mt-n5p{
    margin-top: -7px !important;
    margin-bottom: -7px !important;
} */
.cursor{
    cursor: pointer !important;
}

.vehicles-list {
    max-height: 350px !important;
    font-size: 14px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.top-item{
    top: 0 !important;
}
.normal-cursor{
    cursor: default !important;
}

.font-14p{
    font-size: 14px !important;
}
.btn-w200p{
    /* width: 200px !important; */
}

.font-bold700{
    font-weight: 700 !important;
}

.btn-outline-secondary:hover{
    background-color: rgb(248,249,250) !important;
}
.cst-top-position{
    top: 2.5rem !important;
}
.td-fixed-height{
    height: 41px !important;
}

.img-w60{
    width: 65% !important;
}
body{
    font-size: 14px !important;
    letter-spacing: 0.03rem !important;
}
.sbd-sm{
    font-size: 12px !important;
}
.sbd-mt{
    margin-top: 18px !important;
}
.w-90{
    width: 95% !important;
}
.dropdown-item{
    font-size: 14px !important;
}
.sbd-result-table{
    min-height: 70vh !important;
    max-height: 70vh !important;
    overflow: auto !important;
}

.sbd-result-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.sbd-result-table::-webkit-scrollbar-track {
    border-radius: 20px;
}

.sbd-result-table::-webkit-scrollbar-thumb {
    background-color:#E9E9E9 ;
    border-radius: 20px;
}
td{
    white-space: nowrap;
}
.deep-dive{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    width: 33.1%;
    height: 100vh;
    background-color: #fff;
}
.deep-dive-container{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.3);
}

.deepfive-btn-na{
    height: 25px !important;
    width: 90px !important;
    background: rgb(230,230,230) !important;
    text-align: center !important;
    font-size: 12px !important;
    color: #808080 !important;
    border-radius: 4px !important;
}
.deepfive-btn-other{
    height: 25px !important;
    width: 90px !important;
    background: rgb(226,229,255) !important;
    text-align: center !important;
    font-size: 12px !important;
    color: #6F7CFF !important;
    border-radius: 4px !important;
}
.deepfive-btn-green{
    height: 25px !important;
    width: 90px !important;
    background: rgb(212,239,223) !important;
    text-align: center !important;
    font-size: 12px !important;
    color: #27AE60 !important;
    border-radius: 4px !important;
}

.sbd-trim-tbl{
    min-height: 70.5vh;
    max-height: 70.5vh;
    overflow: auto;
    margin: 1rem;
}
.table-bordered_2 th {
    position: sticky;
    top: 0; /* Stick header to the top of the container */
    background-color: white; /* Optional: Set background color for header */
}
.table-bordered_2 td, .table-bordered_2 th{
    border-bottom: solid 1px #E9E9E9;
    height: 40px;
    overflow: hidden;
    border: solid 1px #E9E9E9 !important;
    /* min-width: 170px; */
    max-width: 180px;
}